import { setupTheme } from '@weareredlight/design-system'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import { bioXcelleratorTheme } from 'theme/setup'

import './index.css'

// import awsExports from './aws-exports'
// Amplify.configure(awsExports)
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType:
      process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
  },
})

setupTheme({
  userColors: bioXcelleratorTheme.colors,
  userSizes: bioXcelleratorTheme.sizes,
  userShadows: bioXcelleratorTheme.shadows,
  userMedia: bioXcelleratorTheme.media,
  fontFamily: bioXcelleratorTheme.font,
})

import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
