import * as yup from 'yup'

import type { ProcedureInputType } from 'types/procedures'
import type { ProcedureProviderType } from 'types/providers'

import {
  NewProviderFields,
  ProceduresFields,
} from 'components/Procedures/utils'
import i18next from 'i18n'
import {
  Certification,
  ProcedureMedicalServiceTypeEnum,
  ProcedurePreparationTypeEnum,
  ProcedureProductSpecialityEnum,
  ProcedureProviderTypeEnum,
  ProcedureTypeEnum,
  Specialities,
} from 'utils/enums'

const providerTypeSchema: yup.Schema<ProcedureProviderType> = yup
  .object()
  .shape({
    [NewProviderFields.CERTIFICATION]: yup
      .string()
      .required(i18next.t('Certification is required'))
      .oneOf(Object.values(Certification), i18next.t('Invalid Certification')),
    [NewProviderFields.SPECIALITY]: yup
      .string()
      .nullable()
      .oneOf(Object.values(Specialities), i18next.t('Invalid Speciality'))
      .when(NewProviderFields.CERTIFICATION, ([cert], schema) => {
        return cert === Certification.DOCTOR
          ? schema.required(i18next.t('Certification is required'))
          : schema
      }),
    [NewProviderFields.COUNT]: yup
      .number()
      .required(i18next.t('Provider count is required'))
      .min(1, i18next.t('Provider count must be at least 1')),
  })

const durationSchema = (label: string) =>
  yup
    .string()
    .matches(/^[0-9:]+$/, `${label} ${i18next.t('is required')}`)
    .required(`${label} ${i18next.t('is required')}`)

export const ProcedureInputSchema: yup.Schema<ProcedureInputType> = yup
  .object()
  .shape({
    [ProceduresFields.NAME]: yup
      .string()
      .required(i18next.t('Procedure name is required')),
    [ProceduresFields.CRM_CODE]: yup.string().nullable(),
    [ProceduresFields.IS_CELL_BANK]: yup
      .boolean()
      .required(i18next.t('Requires Cell Bank')),
    [ProceduresFields.NUMBER_OF_CELLS]: yup
      .number()
      .transform((value, originalValue) =>
        (originalValue === '' ? null : value),
      )
      .nullable()
      .typeError(i18next.t('Number of stem cells is required'))
      .min(1, i18next.t('Number of stem cells must be at least 1')),
    [ProceduresFields.DESCRIPTION]: yup.string().nullable(),
    [ProceduresFields.RULES]: yup.string().nullable(),
    [ProceduresFields.PREFIX]: yup.object({
      [ProceduresFields.MAIN_TIME]: durationSchema(
        i18next.t('Procedure duration'),
      ),
      [ProceduresFields.BEFORE_TIME]: durationSchema(i18next.t('Before time')),
    }),
    [ProceduresFields.REQUIRED_PROVIDERS]: yup.array().of(providerTypeSchema),
    [ProceduresFields.SPLIT_PROCEDURES]: yup
      .array()
      .of(yup.string().required())
      .nullable(),
    [ProceduresFields.PRODUCT_SPECIALTY]: yup
      .string()
      .nullable()
      .oneOf(
        Object.values(ProcedureProductSpecialityEnum),
        i18next.t('Invalid Product specialty'),
      ),
    [ProceduresFields.MEDICAL_SERVICE_TYPE]: yup
      .string()
      .nullable()
      .oneOf(
        Object.values(ProcedureMedicalServiceTypeEnum),
        i18next.t('Invalid Medical service type'),
      ),
    [ProceduresFields.PROCEDURE_TYPE]: yup
      .string()
      .nullable()
      .oneOf(
        Object.values(ProcedureTypeEnum),
        i18next.t('Invalid Procedure type'),
      ),
    [ProceduresFields.PROVIDER_TYPE]: yup
      .string()
      .nullable()
      .oneOf(
        Object.values(ProcedureProviderTypeEnum),
        i18next.t('Invalid Provider type'),
      ),
    [ProceduresFields.PREPARATION_TYPE]: yup
      .string()
      .nullable()
      .oneOf(
        Object.values(ProcedurePreparationTypeEnum),
        i18next.t('Invalid Preparation type'),
      ),
    [ProceduresFields.PRE_PREPARATION]: yup.string().nullable(),
    [ProceduresFields.POST_PREPARATION]: yup.string().nullable(),
    [ProceduresFields.IS_ACTIVE]: yup.boolean().nullable(),
  })
