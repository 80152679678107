import { Flex, Input, Text, TextArea } from '@weareredlight/design-system'
import { useTranslation } from 'react-i18next'

import type { ProcedureInputType } from 'types/procedures'
import type { FormType } from 'types/types'

import ProcedureProviderForm from './ProcedureProviderForm'
import SplitProcedureForm from './SplitProcedureForm'
import { ProceduresFields } from './utils'

import Card from 'components/Card'
import {
  ControlledSelect,
  ControlledTimePicker,
  ControlledToggle,
  Form,
  PickerTypes,
} from 'components/Form'
import {
  ProcedureMedicalServiceTypeEnum,
  ProcedurePreparationTypeEnum,
  ProcedureProductSpecialityEnum,
  ProcedureProviderTypeEnum,
  ProcedureTypeEnum,
} from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import {
  medicalServiceTypeLabels,
  procedurePreparationTypeLabels,
  procedureTypeLabels,
  productSpecialtyLabels,
} from 'utils/labels'

const ProcedureForm = ({
  form,
  isLoading,
  ...props
}: FormType<ProcedureInputType>) => {
  const { t } = useTranslation()

  const requiresCellBank = form.watch(ProceduresFields.IS_CELL_BANK)
  return (
    <Form form={form} {...props}>
      <Flex
        align="start"
        gap="xlg"
        className="form-wrapper"
        css={{
          width: '100%',
          '@md': {
            flexDirection: 'column',

            '>div': {
              width: '100%',
            },
          },
        }}
      >
        <Card isLoading={isLoading} width="50%">
          <Flex
            direction="column"
            align="start"
            gap="xlg"
            className="form-content"
          >
            <Text variant="h2">{t('Procedure Details')}</Text>
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <Input
                {...form.register(ProceduresFields.NAME)}
                label={t('Name')}
                placeholder={t('Enter name of procedure')}
                state={
                  form.formState.errors[ProceduresFields.NAME]
                    ? 'error'
                    : 'null'
                }
                errorMsg={form.formState.errors[ProceduresFields.NAME]?.message}
                fullWidth
              />
              <Input
                {...form.register(ProceduresFields.CRM_CODE)}
                label={t('CRM Code (Optional)')}
                placeholder={t('Enter CRM code')}
                state={
                  form.formState.errors[ProceduresFields.CRM_CODE]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.CRM_CODE]?.message
                }
                fullWidth
              />
            </Flex>
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <TextArea
                {...form.register(ProceduresFields.DESCRIPTION)}
                id={ProceduresFields.DESCRIPTION}
                label={t('Description (Optional)')}
                placeholder={t('Enter description of procedure')}
                state={
                  form.formState.errors[ProceduresFields.DESCRIPTION]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.DESCRIPTION]?.message
                }
                fullWidth
              />
              <TextArea
                {...form.register(ProceduresFields.RULES)}
                id={ProceduresFields.RULES}
                label={t('Scheduling Rules (Optional)')}
                placeholder={t('Enter scheduling rules of procedure')}
                state={
                  form.formState.errors[ProceduresFields.RULES]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.RULES]?.message
                }
                fullWidth
              />
            </Flex>
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <ControlledSelect
                name={ProceduresFields.PRODUCT_SPECIALTY}
                options={enum2SelectOptions(
                  ProcedureProductSpecialityEnum,
                  productSpecialtyLabels,
                )}
                label={t('Product Specialty')}
                placeholder={t('Select product specialty')}
                state={
                  form.formState.errors[ProceduresFields.PRODUCT_SPECIALTY]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.PRODUCT_SPECIALTY]
                    ?.message
                }
                fullWidth
              />
              <ControlledSelect
                name={ProceduresFields.MEDICAL_SERVICE_TYPE}
                options={enum2SelectOptions(
                  ProcedureMedicalServiceTypeEnum,
                  medicalServiceTypeLabels,
                )}
                label={t('Medical Service Type')}
                placeholder={t('Select medical service type')}
                state={
                  form.formState.errors[ProceduresFields.MEDICAL_SERVICE_TYPE]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.MEDICAL_SERVICE_TYPE]
                    ?.message
                }
                fullWidth
              />
            </Flex>
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <ControlledSelect
                name={ProceduresFields.PROCEDURE_TYPE}
                options={enum2SelectOptions(
                  ProcedureTypeEnum,
                  procedureTypeLabels,
                )}
                label={t('Procedure Type')}
                placeholder={t('Select procedure type')}
                state={
                  form.formState.errors[ProceduresFields.PROCEDURE_TYPE]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.PROCEDURE_TYPE]
                    ?.message
                }
                fullWidth
              />
              <ControlledSelect
                name={ProceduresFields.PROVIDER_TYPE}
                options={enum2SelectOptions(ProcedureProviderTypeEnum)}
                label={t('Provider Type')}
                placeholder={t('Select provider type')}
                state={
                  form.formState.errors[ProceduresFields.PROVIDER_TYPE]
                    ? 'error'
                    : 'null'
                }
                errorMsg={
                  form.formState.errors[ProceduresFields.PROVIDER_TYPE]?.message
                }
                fullWidth
              />
            </Flex>
            <ControlledSelect
              name={ProceduresFields.PREPARATION_TYPE}
              options={enum2SelectOptions(
                ProcedurePreparationTypeEnum,
                procedurePreparationTypeLabels,
              )}
              label={t('Preparation Type')}
              placeholder={t('Select preparation type')}
              state={
                form.formState.errors[ProceduresFields.PREPARATION_TYPE]
                  ? 'error'
                  : 'null'
              }
              errorMsg={
                form.formState.errors[ProceduresFields.PREPARATION_TYPE]
                  ?.message
              }
              fullWidth
            />
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <ControlledTimePicker
                type={PickerTypes.DURATION}
                name={ProceduresFields.PRE_PREPARATION}
                label={t('Pre Preparation')}
                error={
                  form.formState.errors[ProceduresFields.PRE_PREPARATION]
                    ?.message
                }
              />
              <ControlledTimePicker
                type={PickerTypes.DURATION}
                name={ProceduresFields.POST_PREPARATION}
                label={t('Post Preparation')}
                error={
                  form.formState.errors[ProceduresFields.POST_PREPARATION]
                    ?.message
                }
              />
            </Flex>
            <Flex align="start" gap="xlg" className="fields-wrapper">
              <Flex align="start" direction="column" gap="sm">
                <ControlledTimePicker
                  type={PickerTypes.DURATION}
                  name={`${ProceduresFields.PREFIX}.${ProceduresFields.MAIN_TIME}`}
                  label={t('Duration')}
                  error={
                    form.formState.errors[ProceduresFields.PREFIX]?.[
                      ProceduresFields.MAIN_TIME
                    ]?.message
                  }
                />
                <ControlledTimePicker
                  type={PickerTypes.DURATION}
                  name={`${ProceduresFields.PREFIX}.${ProceduresFields.BEFORE_TIME}`}
                  label={t('Time Before')}
                  description={t(
                    'It determines the time needed to prepare the room and all resources before the procedure.',
                  )}
                  error={
                    form.formState.errors[ProceduresFields.PREFIX]?.[
                      ProceduresFields.BEFORE_TIME
                    ]?.message
                  }
                />
              </Flex>
              <Flex direction="column" align="start" gap="xlg">
                <ControlledToggle
                  name={ProceduresFields.IS_CELL_BANK}
                  label={t('Requires Cell Bank')}
                />
                {requiresCellBank && (
                  <Input
                    {...form.register(ProceduresFields.NUMBER_OF_CELLS)}
                    label={t('Number of Stem Cells')}
                    placeholder={t('Enter number of stem cells')}
                    type="number"
                    state={
                      form.formState.errors[ProceduresFields.NUMBER_OF_CELLS]
                        ? 'error'
                        : 'null'
                    }
                    errorMsg={
                      form.formState.errors[ProceduresFields.NUMBER_OF_CELLS]
                        ?.message
                    }
                    containerProps={{ style: { minWidth: 50 } }}
                    fullWidth
                  />
                )}
                <ControlledToggle
                  name={ProceduresFields.IS_ACTIVE}
                  label={t('Active')}
                />
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card isLoading={isLoading} width="50%">
          <Flex gap="xxlg" direction="column" align="start">
            <ProcedureProviderForm />
            <SplitProcedureForm />
          </Flex>
        </Card>
      </Flex>
    </Form>
  )
}

export default ProcedureForm
