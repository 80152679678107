import type { ProcedureProviderType } from 'types/providers'

export enum ProceduresFields {
  PREFIX = 'durationData',
  NAME = 'name',
  DESCRIPTION = 'description',
  MAIN_TIME = 'mainTime',
  BEFORE_TIME = 'beforeTime',
  REQUIRES_CELL_BANK = 'requiresCellBank',
  IS_CELL_BANK = 'isCellBank',
  NUMBER_OF_CELLS = 'numberOfStemCells',
  CRM_CODE = 'crmCode',
  REQUIRED_PROVIDERS = 'providerTypes',
  RULES = 'schedulingRules',
  PRODUCT_SPECIALTY = 'productSpecialty',
  MEDICAL_SERVICE_TYPE = 'medicalServiceType',
  PROCEDURE_TYPE = 'procedureType',
  PROVIDER_TYPE = 'providerType',
  PREPARATION_TYPE = 'preparationType',
  PRE_PREPARATION = 'prePreparation',
  POST_PREPARATION = 'postPreparation',
  SPLIT_PROCEDURES = 'splitProcedures',
  IS_ACTIVE = 'isActive',
}

export enum NewProviderFields {
  CERTIFICATION = 'certification',
  SPECIALITY = 'speciality',
  COUNT = 'count',
}

export const newProvider: ProcedureProviderType = {
  [NewProviderFields.CERTIFICATION]: null,
  [NewProviderFields.SPECIALITY]: null,
  [NewProviderFields.COUNT]: 1,
}
